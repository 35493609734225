body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #9D0208;
}
p {
  font-weight: 500;
}
.bkg-red {
  background-color: #9D0208;
}
.full-h {
  height: 100vh;
}
.full-w {
  width: 100vw;
}
.text-centered {
  text-align: center;
}
.text-white {
  color: white;
}
.floating-section {
  top: 11vh;
  margin-bottom: 20vh;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 19px 4px rgba(0, 0, 0, 0.6);
  -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.loader-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}
.pop-section {
  margin-top: 2vh;
  margin-bottom: 2vh;
  background-color: white;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 2px 19px 4px rgba(0, 0, 0, 0.6);
  -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}
.mb-2vh {
  margin-bottom: 2vh;
}
.bold {
  font-weight: bold;
}
.mt-2vh {
  margin-top: 2vh;
}
.centered-content {
  text-align: center;
}
.image-container {
  cursor: pointer;
}
.scroll {
  max-height: 850px;
  overflow-y: auto;
}
.user-form {
  border-right: 2px solid #efefef;
  /* height: 900px; */
}
.priceHead {
  color: white;
  font-weight: bold;
  background-color: green;
  width: 25%;
  text-align: center;
  border-radius: 5px;
}
.priceBoxes {
  color: white;
  font-weight: bold;
  background-color: green;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
}
.mainImage {
  width: 80%;
}
.inputRow {
  margin-bottom: 15px;
}
@media only screen and (max-width: 576px) {
  .priceHead {
    width: 45%;
    margin-left: 10px;
  }
  .mainImage {
    width: 100%;
  }
  .last {
    margin-bottom: 200px;
  }
  .floating-section {
    margin-right: 20px;
    margin-left: 20px;
  }
  .user-form {
    border-right: 0px solid #efefef;
    height: auto;
  }
  .scroll {
    overflow-y: inherit;
    max-height: none;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  [class*="col-"] {
    padding-right: 0px;
    padding-left: 0px;
}
}