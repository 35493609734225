html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url("https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2") format('woff2');
  font-display: swap;
}
.anicards {
  transition: opacity 0.5s;
  opacity: 1;
  /* margin-top: 300px; */
}
.anicards.hide {
  opacity: 0;
  pointer-events:none;
}
.animatedCardsSection{
  padding-top:20vh;
}
.dlbtn {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 1vh;
}
.section-description{
  padding-left: 4%;
}
.app-view{
  padding-top: 25%;
}
.mobileViewCards {
  display: none;
}
.emailInput {
  width: 40%;
  height: 45px;
  margin-right: 20px;
}
.emailSubmission {
  width: 25%;
  height: 45px;
  border-radius: 5px;
  background-color: #9D0208;
  border: 0px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}
.altSubmission {
  width: 25%;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  border: 0px;
  color: black;
  font-weight: 500;
}
.mobileElementContainer {
  display: none;
}
.navbar-brand {
  padding: 0px 15px !important;
}
.navbar-brand img{
  width: 55px;
  border-radius: 100%;
}
@media only screen and (max-width: 867px) {
.section-intro {
  display: none;
}
.mobileViewCards {
  display: block;
  width: 100%;
  margin-bottom: 5%;
  border-radius: 3%;
}
.section-description {
  padding-right: 4%;
}
.emailSubmission {
  width: 40%;
}
.emailInput {
  width: 50% !important;
}
.mobileElementContainer {
  display: block;
  width: 49%;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-brand img{
  width: 35px;
}
}
