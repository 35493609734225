.mappedCard {
  aspect-ratio: 5/7;
  border-radius: 5px;
  border-color: transparent;
  border-width: 1px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1vh;
}

