body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #9D0208;
}
p {
  font-weight: 500;
}
.bkg-red {
  background-color: #9D0208;
}
.full-h {
  height: 100vh;
}
.full-w {
  width: 100vw;
}
.text-centered {
  text-align: center;
}
.text-white {
  color: white;
}
.floating-section {
  top: 11vh;
  margin-bottom: 20vh;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 19px 4px rgba(0, 0, 0, 0.6);
  transition: box-shadow 150ms ease;
}
.loader-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}
.pop-section {
  margin-top: 2vh;
  margin-bottom: 2vh;
  background-color: white;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 2px 19px 4px rgba(0, 0, 0, 0.6);
  transition: box-shadow 150ms ease;
}
.mb-2vh {
  margin-bottom: 2vh;
}
.bold {
  font-weight: bold;
}
.mt-2vh {
  margin-top: 2vh;
}
.centered-content {
  text-align: center;
}
.image-container {
  cursor: pointer;
}
.scroll {
  max-height: 850px;
  overflow-y: auto;
}
.user-form {
  border-right: 2px solid #efefef;
  /* height: 900px; */
}
.priceHead {
  color: white;
  font-weight: bold;
  background-color: green;
  width: 25%;
  text-align: center;
  border-radius: 5px;
}
.priceBoxes {
  color: white;
  font-weight: bold;
  background-color: green;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
}
.mainImage {
  width: 80%;
}
.inputRow {
  margin-bottom: 15px;
}
@media only screen and (max-width: 576px) {
  .priceHead {
    width: 45%;
    margin-left: 10px;
  }
  .mainImage {
    width: 100%;
  }
  .last {
    margin-bottom: 200px;
  }
  .floating-section {
    margin-right: 20px;
    margin-left: 20px;
  }
  .user-form {
    border-right: 0px solid #efefef;
    height: auto;
  }
  .scroll {
    overflow-y: inherit;
    max-height: none;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  [class*="col-"] {
    padding-right: 0px;
    padding-left: 0px;
}
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}
.Landing {

}

.Landing-header {
  background-color: white;
  height: 150px;
  padding: 20px;
  color: black;
}
.Landing-header img {
  width: 250px;
}

.Landing-title {
  font-size: 1.5em;
}

.Landing-intro {
  font-size: large;
}
.jumbotron {
  background-color: white;
  background-image: url(/static/media/banner_background.1d5552b9.png);
  margin-bottom: 0px;
  padding-bottom:0px;
  padding-top: 10%;
  text-align: left;

  background-size: cover;

}
.jumbotron .btn{
  font-size: calc(.27548vw + 12.71074px) !important;
  padding:calc(.34435vw + 8.38843px) calc(.34435vw + 13.38843px) !important;
  font-weight: 700;
  height: 48px !important;
}
.jumbotron h1{

  color: white;
  font-size:40px;

}

.white {
  background-color: white;
  text-align: center;
  margin-bottom: 3%;


}
.Landing .actions {
  text-align:right;

}
.Landing .bactions {
  text-align:left;

}
.hiwSection{
  margin-top: 5%;
  padding-top: 5%;
}
.hiwoption {
  min-height: 50px;
}




.Landing .content {
  padding-left:20%;
  padding-right:20%;
  background-color:#E7E7E7
}
.Landing .content h4 {
  text-align: center;
}
.jumbotron img {
  width:100%
}
.jumbotron .banner-image {
  margin-top:1em;
}
/* /* @keyframes Landing-logo-spin {
 *
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}/ */
.popupCategories{
  margin-top: -19vw;
  background-color: white;
}
.ProductView-subbar{
  height: 200px;
}
.formGroup {
  text-align: center;
  margin-top: 2%;
}
.formGroup input::-webkit-input-placeholder {
  text-align: left;
}
.formGroup input::-ms-input-placeholder {
  text-align: left;
}
.formGroup input::placeholder {
  text-align: left;
}

.characterForm{
  padding: 2%;
  height: 2400px;
  background-color: white;
}
.characterForm .formImageContainer{
  height: 500px;
  text-align: center;
}
.characterForm .formImageContainer img{
  height: 100%;
}
.characterForm #submitCardBtn {
    margin-top: 2%;
    margin-bottom: 2%;
    background-color: #23DBAA;
    border: 1px solid #23DBAA;
    padding-left: 30px;
    padding-top: 5px;
    /* margin-top: 6px; */
    padding-right: 30px;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    padding-bottom: 2px;
}
.characterForm .cardName {
  width: 100%;
}
.characterForm .formHead {
  text-align: center;
}
.characterForm .symbolContainer img{
  width: 100%;
}
.characterForm .symbolContainer .btn{
  padding: 0px !important;
}
.characterForm .symbolContainer{
  margin-top: 2%;
}
.characterForm .symbolContainer .typeHead{
  margin-top: 3%;
}
.characterForm .entranceHead{
  margin-top: 2%;
}
.characterForm .rarityContainer input{
  position: absolute;
  left: 25%;
}
.characterForm .rarityContainer p{
  position: absolute;
  left: 30%;
  font-size: 16px;
}
.characterForm .abilityContainer input{
  position: absolute;
  left: 20%;
}
.characterForm .abilityContainer p{
  position: absolute;
  left: 32%;
  font-size: 16px;
}
.characterForm .abilityContainer h5 {
  margin-top: 80%;
}
.characterForm .entranceContainer img{
  width: 70%;
}
.characterForm .entranceContainer{
  margin-top: 2%;
}
.characterForm .entranceContainer .typeHead{
  margin-top: 3%;
}
.characterForm .radioForm{
  margin-bottom: 1%;
}
.characterForm #combatAttr{
  font-size: 16px;
  margin-top: 4%;
  float: left;
}
.characterForm .otherContainer .combatHead{
  float: right;
}
.characterForm #cardSet{
  font-size: 16px;
  margin-top: 4%;
  float: left;
}
.characterForm .otherContainer .cardSetHead{
  float: right;
}
.characterForm #submitPost {
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: rgb(35, 152, 219);
  border: 1px solid rgb(35, 152, 219);
  padding-left: 30px;
  padding-top: 5px;
  /* margin-top: 6px; */
  padding-right: 30px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  padding-bottom: 2px;
}
.characterForm .abilitySymbol {
  width: 25%;
    margin-top: 10px;
    float: right;
}
.characterForm .abilityCostContainer{
  margin-bottom: 2%;
}
.formBtn {
  background-color: transparent !important;
  border-color: transparent !important;
}
 h4{
  margin-top:12px !important;
}
.postfile {
	width: 0.1px !important;
	height: 0.1px !important;
	opacity: 0;
	overflow: hidden !important;
	position: absolute;
	z-index: -1;
}
.postfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: gray;
  background-color: transparent;
  display: inline-block;
}

.postfile:focus + label,
.postfile + label:hover {
  background-color: transparent;
}


.postfile + label {
  cursor: pointer;
  font-size: 50px; /* "hand" cursor */

}

.postfile:focus + label {
  outline: none;

}
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
/* =========================Responsiveness==============================================*/
@media only screen and (min-width: 300px) and (max-width: 640px) {
  .jumbotron{
    background-image: none;
    padding-top: 165px;
    height: 510px;
  }
  .jumbotron h1{
    font-size: 26px;
  }
  .jumbotron h3{
    font-size: 18px;
    display: none;
  }
  .jumbotron .btn {
    width: 315px !important;
    height: 40px !important;
    font-weight: 700 !important;
    border-radius: 5px;
  }
  .jumbotron .kep-login-facebook{
    width: 315px !important;
  }
  .googleLogin button{
    font-family: 'Open Sans', sans-serif !important;
    width: 315px !important;
    padding: calc(.34435vw + 8.38843px) calc(.34435vw + 13.38843px);
    font-size: calc(.27548vw + 12.71074px) !important;
    border-radius: 5px !important;

  }
  #bannerpic{
    position: absolute;
    top: 50px;
    left: 0px;
    height: 200px;
    object-fit: cover;
  }
  #installMessage{
    padding-top: 5px;
    /* width: 50%; */
    position: fixed;
    height: 35px;
    bottom: 10px;
    background-color: whitesmoke;
    color: gray;
    text-align: center;
    font-size: 16px;
  }
  #installCaret{
    position: fixed;
    bottom: -1px;
    color: red;
    font-size: 30px;
  }
  .videoContainer iframe{
    width: 100% !important;
    height: 25vh !important;
  }
}

@media only screen and (max-width: 320px) {
  .banner-image {
    display: none
  }
  .Abanner-image {
    display: none
  }
  .jumbotron{
    height: 200px;
  }
}
@media only screen and (min-width: 410px) and (max-width: 420px) {
  .banner-image {
    display: none
  }
  .Abanner-image {
    display: none
  }
}

@media only screen and (min-width: 321px) and (max-width: 639px){
  .banner-image {
    display: none
  }
  .Abanner-image {
    display: none
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen  and (min-width: 641px) and (max-width: 767px) {
  .banner-image {
    display: none
  }
  .Abanner-image {
    display: none
  }
  .jumbocontent h1{
    font-size: 28px;
  }
  .jumbocontent h3{
    font-size: 16px;
  }
  .jumbocontent {
    height: 300px;
  }
  .jumbotron .btn {
    margin-top: 5% !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-image {
    display: none
  }
  .Bbanner-image {
    display: none
  }
  .videoContainer{
    text-align: right;
    margin-top: 20px;
    padding-right: 6em;
  }
  .jumbotron .btn {
    margin-top: 5% !important;
  }
  .jumbocontent h1{
    font-size: 28px;
  }
  .jumbocontent h3{
    font-size: 18px;
  }
  .jumbocontent {
    height: 350px;
  }


}

 /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Abanner-image {
    display: none
  }
  .Bbanner-image {
    display: none
  }
  .videoContainer{
    text-align: right;
    padding-right: 3em;
    margin-bottom: 33px;
  }
  .videoContainer iframe{
    width: 100% !important;
    height: 33vh !important;
  }
  .Landing .content {
    padding-left: 33%;
    padding-right: 33%;
    background-color: #E7E7E7;
    padding-top: 30px;

  }
  .jumbotron{
    height: 600px;
  }
  .jumbotron h1{

    color: white;
    font-size:36px;

  }
  .jumbotron h3{

    color: white;
    font-size:20px;

  }
}
@media only screen and (min-width: 1400px) {
  .Landing .content {
    padding-left: 35%;
    padding-right: 35%;
    background-color: #E7E7E7;
    padding-top: 30px;

  }
  .videoContainer{
    text-align: right;
    padding-right: 3em;
    margin-bottom: 33px;
  }
  .videoContainer iframe{
    width: 100% !important;
    height: 43vh !important;
  }
  .jumbotron{
    height: 700px;
  }
}
@media only screen and (min-width: 1800px) {
  .jumbotron{
    height: 800px;
  }
}

.mappedCard {
  aspect-ratio: 5/7;
  border-radius: 5px;
  border-color: transparent;
  border-width: 1px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1vh;
}


html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url("https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2") format('woff2');
  font-display: swap;
}
.anicards {
  transition: opacity 0.5s;
  opacity: 1;
  /* margin-top: 300px; */
}
.anicards.hide {
  opacity: 0;
  pointer-events:none;
}
.animatedCardsSection{
  padding-top:20vh;
}
.dlbtn {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 1vh;
}
.section-description{
  padding-left: 4%;
}
.app-view{
  padding-top: 25%;
}
.mobileViewCards {
  display: none;
}
.emailInput {
  width: 40%;
  height: 45px;
  margin-right: 20px;
}
.emailSubmission {
  width: 25%;
  height: 45px;
  border-radius: 5px;
  background-color: #9D0208;
  border: 0px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}
.altSubmission {
  width: 25%;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  border: 0px;
  color: black;
  font-weight: 500;
}
.mobileElementContainer {
  display: none;
}
.navbar-brand {
  padding: 0px 15px !important;
}
.navbar-brand img{
  width: 55px;
  border-radius: 100%;
}
@media only screen and (max-width: 867px) {
.section-intro {
  display: none;
}
.mobileViewCards {
  display: block;
  width: 100%;
  margin-bottom: 5%;
  border-radius: 3%;
}
.section-description {
  padding-right: 4%;
}
.emailSubmission {
  width: 40%;
}
.emailInput {
  width: 50% !important;
}
.mobileElementContainer {
  display: block;
  width: 49%;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-brand img{
  width: 35px;
}
}

#logoBanner {
  width: 55%;
}
#productImage {
  width: 90%;
  border: 1px solid transparent;
    border-radius: 10px;
}
#brandingLogo {
  max-height: 80px;
}
@media only screen and (max-width: 867px) {
  #logoBanner {
    width: 80%;
  } 
  #productImage {
    margin-bottom: 5vh;
  }
}
#androidImg {
  width: 25%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.content-android{
  background-color: black;
  text-align: center;
}

.rarityTitle {
  margin-bottom: 2vh;
  margin-top: 2vh;
}
