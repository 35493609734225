#androidImg {
  width: 25%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.content-android{
  background-color: black;
  text-align: center;
}
