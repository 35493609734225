#logoBanner {
  width: 55%;
}
#productImage {
  width: 90%;
  border: 1px solid transparent;
    border-radius: 10px;
}
#brandingLogo {
  max-height: 80px;
}
@media only screen and (max-width: 867px) {
  #logoBanner {
    width: 80%;
  } 
  #productImage {
    margin-bottom: 5vh;
  }
}